import React, { useState, useEffect } from 'react';
import { useFetchData } from '../../hooks/useFetchData';
import { fetchPNL } from '../../services/apiService';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import DataTable from '../Page/DataTable';

const PNLPage = () => {
  let { products, brentPrices } = useFetchData();
  useDocumentTitle("PNL");

  const fetchData = async () => {
    const result = await fetchPNL();
    return { "pnlData": result["pnl"], "market_changes": result["market_changes"], "maturities": result["maturities"] }
  }
  const [pnlData, setPNLData] = useState([]);
  const [marketChangeData, setMarketChangeData] = useState([]);
  const [months, setMonths] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchDataAsync = async () => {
      try {
        const { pnlData, market_changes, maturities } = await fetchData();
        setPNLData(pnlData);
        setMarketChangeData(market_changes)
        setMonths(maturities)
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchDataAsync();
  }, [fetchData]);

  products = products.filter(p =>
    p.name !== "H-F_Netback"
  )

  if (!brentPrices || brentPrices.length === 0 || !products || products.length === 0) {
    return null
  }

  return (<div>
    <DataTable data={pnlData} loading={loading} error={error} months={months} />
    <DataTable data={marketChangeData} loading={loading} error={error} months={months} />
  </div>
  )
};

export default PNLPage;
