import React, { useState } from 'react';
import ColumnInput from '../ColumnInput';
import { formatNumber } from '../../helpers/formatNumber';
import { getBackgroundColorClass } from '../../helpers/productHelper';
import Tooltip from './Tooltip';
import "../../styles/shared.css";
import { updateInBulk } from '../../services/apiService';
import { useUser } from '../../context/UserContext';
import { formatDate } from '../../helpers/timeHelper';

const columnsThreeDecimals = new Set([
  "MB_Propane:Swap",
  "MB_Propane:T/S",
  "C3_N_TET:Swap",
  "C3_N_TET:T/S",
  "Butane Swaps:nC4 Non-TET",
  "Butane Swaps:IsoC4MB",
])

const getNumberOfDecimals = (column_name, product_name) => {
  const key = `${product_name}:${column_name}`
  return columnsThreeDecimals.has(key) ? 3 : 2
}

const ProductMainTable = ({
  product,
  dates,
  updateValue,
  handleKeyDown,
  selectedProductName,
  setSelectedColumn,
  selectedColumn,
  selectedCell,
  setSelectedCell,
  productIndex,
  focusNextInput,
  inputRefs
}) => {
  const columns = product?.columns || []; // Extract columns

  const { userName } = useUser();
  const [selectedRow, setSelectedRow] = useState(null); // Track selected row


  // Handle selecting a column
  const handleColumnSelect = (colIndex, rowIndex) => {
    setSelectedColumn(colIndex);
    setSelectedRow(rowIndex); // Track the row the user clicked on
  };

  // Handle pasting clipboard data into the selected column starting from the clicked row
  const handlePaste = async (event) => {
    event.preventDefault()
    const column = columns[selectedColumn];
    if (selectedProductName !== product.name || selectedColumn === null || selectedRow === null || !column.quotations) return; // Ensure a column is selected and a row is selected

    // Ensure clipboard data is accessible
    let clipboardData = "";
    if (event.clipboardData) {
      clipboardData = event.clipboardData.getData('text');
    } else if (navigator.clipboard) {
      // Fallback for modern browsers with `navigator.clipboard`
      clipboardData = await navigator.clipboard.readText();
    }
    const pastedValues = clipboardData
      .split(/\r?\n/)
      // One of the butane columns may have a % sign
      .map((value) => value.trim().replace(/%/g, ""))
      .filter((value) => value !== "");


    // Update the selected column starting from the selected row with the pasted values
    updateValuesFromPaste(pastedValues, column, userName, product, selectedRow);
  };

  return (
    <div onPaste={handlePaste}> {/* Capture paste events */}
      <table className="excel-table">
        <thead>
          <tr>
            {columns.map((column, colIndex) => (
              <th
                productIndex={colIndex}
                className={
                  selectedProductName === product.name && selectedColumn === colIndex
                    ? 'selected-column'
                    : ''
                }
              >
                {column.name}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {dates.map((_, rowIndex) => (
            <tr productIndex={rowIndex}>
              {columns.map((column, colIndex) => {
                const entry = column.quotations ? column.quotations[rowIndex] : undefined;
                const isTsColumn = column.quotations;
                const value = entry ? entry.value : column.value[rowIndex]?.value || column.value[rowIndex] || "";
                const last_update = entry ? entry.last_update : column.value[rowIndex]?.last_update;
                const last_update_user = entry ? entry.last_update_user : column.value[rowIndex]?.last_update_user;
                const isEditableEntry = !isTsColumn && column.value[rowIndex]?.index === rowIndex;

                if (!inputRefs[rowIndex]) {
                  inputRefs[rowIndex] = []; // Initialize a new array for this row if not already done
                }

                if (!inputRefs[rowIndex][colIndex]) {
                  inputRefs[rowIndex][colIndex] = null; // Ensure the specific cell is initialized
                }

                const numDecimals = getNumberOfDecimals(column.name, product.name)
                if (isTsColumn || isEditableEntry) {
                  const previous_value = entry ? entry.previous_value : column.value[rowIndex]?.previous_value;
                  const change_direction = value > previous_value ? 1 : value === previous_value ? 0 : -1;
                  const lastUpdateClass = userName === last_update_user ? "dark" : getBackgroundColorClass(last_update, change_direction);
                  const class_name = isTsColumn ? `column-input-container-${lastUpdateClass}` : `column-input-extra-cell-${lastUpdateClass}`;
                  const entry_id = entry?.id;
                  const index = rowIndex;

                  return (
                    <td productIndex={colIndex} className={class_name} onClick={() => {
                      handleColumnSelect(colIndex, rowIndex)
                      setSelectedRow(rowIndex)
                    }
                    }>
                      <Tooltip content={`Last updated by ${last_update_user || ''} on ${last_update || ''}`}>
                        <ColumnInput
                          onClick={() =>
                            setSelectedCell({ productIndex, rowIndex, colIndex })
                          }
                          value={value}
                          onChange={(e) => updateValue(product.name, `${column.name}-${entry_id}-${index}`, e.target.value, false)}
                          onKeyDown={handleKeyDown}
                          product_name={product.name}
                          column_name={column.name}
                          entry_id={entry_id}
                          index={index}
                          onFocusNext={(rowDir, colDir) => focusNextInput(rowIndex, colIndex, rowDir, colDir, productIndex)}
                          numDecimals={numDecimals}
                          disabled={false}
                          inputRef={(el) => {
                            if (!inputRefs[rowIndex]) inputRefs[rowIndex] = [];
                            inputRefs[rowIndex][colIndex] = el;
                          }}
                          isSelected={
                            selectedCell.productIndex === productIndex &&
                            selectedCell.rowIndex === rowIndex &&
                            selectedCell.colIndex === colIndex
                          }
                        />
                      </Tooltip>
                    </td>
                  );
                } else {
                  const displayValue = formatNumber(parseFloat(value), numDecimals);
                  return (
                    <td productIndex={colIndex}>
                      {displayValue}
                    </td>
                  );
                }
              })}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default React.memo(ProductMainTable);

// Function to update values from pasted data starting from a specific row
const updateValuesFromPaste = async (pastedValues, column, userName, product, startRow) => {
  const last_update = formatDate(new Date())
  const body = []
  const is_percent_column = column.name.includes('%')
  pastedValues.forEach((value, rowIndex) => {
    const entryIndex = startRow + rowIndex; // Adjust to start from the clicked row
    if (entryIndex < column.quotations.length) {
      const entry = column.quotations[entryIndex];
      const entry_id = entry?.id;

      const item = {
        product_id: product.id,
        product_name: product.name,
        column_name: column.name,
        entry_id: entry_id,
        new_value: is_percent_column ? parseFloat(value) / 100 : parseFloat(value),
        last_update_user: userName,
        last_update: last_update,
        index: entryIndex
      }
      body.push(item)
    }
  });

  await updateInBulk(body)
}
