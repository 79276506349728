import api from "./api"

export const fetchPNL = async () => {
    try {
        const response = await api.get('/pnl/');
        return response.data;
    } catch (error) {
        console.error('Error fetching PNL:', error);
        throw error;
    }
};

export const fetchPositions = async () => {
    try {
        const response = await api.get('/positions/');
        return response.data;
    } catch (error) {
        console.error('Error fetching Positions:', error);
        throw error;
    }
};

export const getFuturesData = async () => {
    try {
        const response = await api.get('/futures/');
        return response.data;
    } catch (error) {
        console.error('Error fetching Positions:', error);
        throw error;
    }
};

export const updateInBulk = async (body) => {
    try {
        const response = await api.put('/mtm/bulk_update', body, {
            headers: { 'Content-Type': 'application/json' }
        });
        return response.data;
    } catch (error) {
        console.error('Error performing bulk update:', error);
        throw error;
    }
};

export const fetchProducts = async () => {
    try {
        const response = await api.get('/products/');
        return response.data;
    } catch (error) {
        console.error('Error fetching products:', error);
        throw error;
    }
};

export const updateMTMEntry = async (body) => {
    try {
        const response = await api.put('/mtm/update_entry', body, {
            headers: { 'Content-Type': 'application/json' }
        });
        return response.data;
    } catch (error) {
        console.error('Error updating entry:', error);
        throw error;
    }
};

export const updateMTMExtraInputValue = async (body) => {
    try {
        const response = await api.put('/mtm/update_extra_input_value', body, {
            headers: { 'Content-Type': 'application/json', },
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching Positions:', error);
        throw error;
    }
};

export const fetchExcelURL = async () => {
    try {
        const response = await api.get('/products/lpg_to_excel');
        return response;
    } catch (error) {
        console.error('Error fetching products:', error);
        throw error;
    }
};