export const formatNumber = (value, numDecimals = 2) => {
    if (isNaN(value) || value === "") {
        return "";
    }

    const isInteger = Number.isInteger(value);

    return new Intl.NumberFormat('en-US', {
        minimumFractionDigits: isInteger ? 0 : numDecimals,
        maximumFractionDigits: numDecimals
    }).format(value);
};
