import '../../styles/ExcelLikePage.css';
import ErrorPage from './ErrorPage';
import IndexColumn from '../IndexColumn';
import LoadingAnimation from '../LoadingAnimation';
import ProductInputs from '../ProductInputs'; // Import the new component
import ProductTable from '../Page/ProductTable';
import React, { useEffect, useState } from 'react';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import { getDatesFromProducts } from '../../helpers/timeHelper';
import { useFetchData } from '../../hooks/useFetchData';
import { useWebSocket } from '../../hooks/useWebSocket';
import { fetchExcelURL, fetchPNL } from '../../services/apiService';
import { formatNumber } from '../../helpers/formatNumber';

const isEditable = (c, row) => {
  if (c.quotations) return true;
  if (!Array.isArray(c.value)) return false;
  const v = c.value[row]
  if (typeof v === "object" && v?.name) return true;
  return false;
};

function parsePNLData(data, maturities) {
  const yearValues = {};

  // Find the "TOTAL PNL" entry in data
  const totalPNLEntry = data.find(entry => entry.category === "TOTAL PNL");
  if (!totalPNLEntry) return []; // Return empty array if "TOTAL PNL" entry is not found

  const values = Object.values(totalPNLEntry.total[0].data); // Get the data values for "TOTAL PNL"
  const minLength = Math.min(values.length, maturities.length);

  for (let i = 0; i < minLength; i++) {
    const year = maturities[i].split("-")[0];
    const value = values[i];

    // Sum the values by year
    yearValues[year] = (yearValues[year] || 0) + value;
  }

  // Convert the yearValues object into an array of { year, value } objects
  return Object.keys(yearValues)
    .sort()
    .map(year => ({
      year,
      value: yearValues[year]
    }));
}

const YearlyValuesTable = ({ data }) => {
  return (
    <table className="yearly-values-table">
      <thead>
        <tr>
          <th>Year</th>
          <th>P&L</th>
        </tr>
      </thead>
      <tbody>
        {data.map((item) => (
          <tr productIndex={item.year}>
            <td>{item.year}</td>
            <td>{formatNumber(item.value, 0)}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

const LPGPage = () => {
  let { products, setProducts, setMaturities, loading, setLoading, error, brentPrices, updateValue, handleKeyDown, updateValueExtraInput } = useFetchData();
  const extra_inputs = products.filter(p => p["extra_input"]).map(p => {
    return {
      "productName": p.name,
      "parameters": p["extra_input"]
    }
  }) || null;
  const [pnl, setPNL] = useState(undefined)

  const [isDownloadingReport, setIsDownloadingReport] = useState(false)
  const [selectedProductName, setSelectedProductName] = useState(null); // Track selected product
  const [selectedColumn, setSelectedColumn] = useState(null); // Track selected column
  const [inputRefsMap, setInputRefsMap] = useState({}); // Holds refs for all products
  const registerInputRefs = (productName, refs) => {
    setInputRefsMap((prev) => ({
      ...prev,
      [productName]: refs,
    }));
  };

  const [selectedCell, setSelectedCell] = useState({
    productIndex: 0,
    rowIndex: 0,
    colIndex: 0,
  });

  const handleDownload = async () => {
    setIsDownloadingReport(true)
    try {
      const response = await fetchExcelURL()
      if (response.status !== 200 || !response.data.url) {
        throw new Error("Error fetching Excel URL");
      }
      const data = response.data
      const fileUrl = data.url;

      const link = document.createElement("a");
      link.href = fileUrl;
      link.download = "LPG_Report.xlsx";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading file", error);
    } finally {
      setIsDownloadingReport(false)
    }
  };

  const focusNextInput = (rowIndex, colIndex, rowDirection = 0, colDirection = 0, productIndex = -1) => {
    const productsLength = products.length;
    const currentProduct = products[productIndex];
    if (!currentProduct) return;

    const nextRow = rowIndex + rowDirection;
    // Boundary checks for rows
    if (nextRow < 0 || nextRow >= dates.length) return;

    const columns = currentProduct.columns || [];

    const editableCols = getEditableColumns(columns, nextRow);

    const firstEditableCol = editableCols[0]
    const lastEditableCol = editableCols[editableCols.length - 1]

    const nextColIdx = colDirection + editableCols.findIndex(x => x === colIndex)
    // Next editable column (or next product)
    let nextCol = nextColIdx < editableCols.length ? editableCols[nextColIdx] : columns.length + 1
    if (nextColIdx < 0) {
      nextCol = -1
    } else {
      nextCol = nextColIdx < editableCols.length ? editableCols[nextColIdx] : columns.length + 1
    }
    let nextProductIndex = productIndex;

    // Handle navigation to previous products
    const moveToPreviousProduct = () => {
      do {
        nextProductIndex -= 1;
        if (nextProductIndex < 0) return false;
        const prevProductColumns = products[nextProductIndex]?.columns || [];
        const editableCols = getEditableColumns(prevProductColumns, nextRow);
        nextCol = editableCols.length > 0 ? editableCols[editableCols.length - 1] : -1;
      } while (nextProductIndex >= 0 && nextCol < 0);
      return true;
    };

    // Handle navigation to next products
    const moveToNextProduct = () => {
      do {
        nextProductIndex += 1;
        if (nextProductIndex >= productsLength) return false;
        const nextProductColumns = products[nextProductIndex]?.columns || [];
        const editableCols = getEditableColumns(nextProductColumns, nextRow);
        nextCol = editableCols.length > 0 ? editableCols[0] : -1;
      } while (nextProductIndex < productsLength && nextCol < 0);
      return true;
    };

    if (colDirection !== 0) {
      // Adjust next column if out of bounds
      // They depend on the row!!
      if (nextCol < firstEditableCol) {
        moveToPreviousProduct()
      } else if (nextCol > lastEditableCol) {
        moveToNextProduct()
      }
      // If no valid column is found, exit
      if (nextCol < 0 || nextCol >= products[nextProductIndex].columns.length) return;
    }

    // Update selected cell and focus input
    setSelectedCell({
      productIndex: nextProductIndex,
      rowIndex: nextRow,
      colIndex: nextCol,
    });

    const nextProductName = products[nextProductIndex]?.name;
    inputRefsMap[nextProductName]?.[nextRow]?.[nextCol]?.focus();

    function getEditableColumns(columns, row) {
      const editableCols = [];
      for (let i = 0; i < columns.length; ++i) {
        const col = columns[i];
        if (isEditable(col, row)) {
          editableCols.push(i);
        }
      }
      return editableCols;
    }
  }

  useEffect(() => {
    if (!products || products.length === 0) {
      return
    }
    const fetchData = async () => {
      const result = await fetchPNL()
      const data = result["pnl"]
      const yearlyPNL = parsePNLData(data, result["maturities"])
      setPNL(yearlyPNL)
    };
    fetchData()
  }, [products])

  useWebSocket(setProducts, setMaturities);
  useDocumentTitle("LPG");

  if (loading) {
    return <LoadingAnimation />;
  }

  if (error) {
    return <ErrorPage error={error} />
  }

  const { dates, quarters, uniqueQuarters, years, uniqueYears } = getDatesFromProducts(products);
  return (
    <>
      {pnl && <YearlyValuesTable data={pnl} />}
      <button className='to-excel-button' onClick={handleDownload}>
        {isDownloadingReport ? "Downloading..." : "Generate Excel Report"}
      </button>

      <div className="excel-container">
        <IndexColumn dates={dates} brentPrices={brentPrices} quarters={uniqueQuarters} years={uniqueYears} pnl={pnl} />
        {products.map((product, productIndex) => (
          <ProductTable
            key={productIndex}
            productIndex={productIndex}
            product={product}
            dates={dates}
            quarters={quarters}
            years={years}
            selectedCell={selectedCell}
            setSelectedCell={setSelectedCell}
            selectedColumn={selectedColumn}
            setSelectedColumn={setSelectedColumn}
            selectedProductName={selectedProductName}
            setSelectedProductName={setSelectedProductName}
            productsLength={products.length}
            focusNextInput={focusNextInput}
            registerInputRefs={registerInputRefs} // Pass callback to register refs
            updateValue={updateValue}
            handleKeyDown={handleKeyDown}
          />
        ))}
      </div>

      {extra_inputs && extra_inputs.map(extra_input => (
        <ProductInputs
          extra_inputs={extra_input}
          updateValue={updateValueExtraInput}
        />
      ))
      }
    </>
  );
}


export default LPGPage;