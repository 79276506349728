import React, { useState, useEffect, useRef } from 'react';
import '../styles/ColumnInput.css';
import { useUser } from '../context/UserContext';

const directionMap = {
  Enter: [1, 0],
  ArrowDown: [1, 0],
  ArrowUp: [-1, 0],
  ArrowRight: [0, 1],
  ArrowLeft: [0, -1],
};

const ColumnInput = ({
  value, 
  onChange, 
  onKeyDown, 
  onFocusNext, 
  disabled, 
  numDecimals = 2, 
  inputRef,
  product_name, 
  column_name,
  entry_id, 
  index, 
  isSelected,
  onClick, // New prop for handling click
}) => {
  const { accessLevel } = useUser();
  const is_percent_column = column_name.includes("%");
  const [inputValue, setInputValue] = useState(value?.toString() || "");
  const [hasEdited, setHasEdited] = useState(false); // Tracks if the cell is being edited
  const [isSubmitting, setIsSubmitting] = useState(false); // Tracks if the value is being saved

  const localRef = useRef(null);

  useEffect(() => {
    if (!isSubmitting) {
      // Only update inputValue from value if not submitting
      setInputValue(
        is_percent_column ? (value * 100).toFixed(numDecimals) : value.toString()
      );
    }
  }, [value, is_percent_column, numDecimals, isSubmitting]);

  useEffect(() => {
    if (isSelected) {
      (inputRef?.current || localRef.current)?.focus();
    }
  }, [isSelected, inputRef]);

  const handleBlur = () => {
    if (hasEdited) {
      formatAndSetInputValue();
    }
  };

  const [hasStartedTyping, setHasStartedTyping] = useState(false); // Track if typing has started

  const handleInputChange = (e) => {
    if (accessLevel === "reader") return;
  
    const rawValue = e.target.value.replace("%", "");
  
    if (!hasStartedTyping) {
      // Clear the existing value on the first keypress
      setInputValue(rawValue.slice(-1)); // Only use the last character typed
      setHasStartedTyping(true);
    } else {
      setInputValue(rawValue); // Continue updating the value normally
    }
  
    setHasEdited(true); // Mark the cell as edited
  };

  const isPasteEvent = (e) => {
    return ["Control", "v"].includes(e.key)
  }
  
  const handleKeyDown = (e) => {
    if (accessLevel === "reader") return;
    if(isPasteEvent(e)){
      return
    }
  
    const wasEnterKey = e.key === "Enter";
    if (wasEnterKey || Object.keys(directionMap).includes(e.key)) {
      e.preventDefault();
  
      // Save the value before navigating
      formatAndSetInputValue();
  
      // Notify parent about the key press
      const navigationEvent = { ...e, key: 'Enter' }; // Mimic Enter for parent handling
      onKeyDown(navigationEvent, product_name, `${column_name}-${entry_id}-${index}`);
  
      // Handle navigation if necessary
      if (Object.keys(directionMap).includes(e.key) && onFocusNext) {
        const [rowDirection, colDirection] = directionMap[e.key];
        onFocusNext(rowDirection, colDirection);
      }
  
      setHasStartedTyping(false); // Reset typing state after navigation
    }
  };

  const formatAndSetInputValue = () => {
    const parsedValue = parseFloat(inputValue.replace("%", ""));
    const processedValue = !isNaN(parsedValue)
      ? is_percent_column
        ? parsedValue / 100
        : parsedValue
      : null;

    if (processedValue !== value) {
      setIsSubmitting(true); // Indicate that the value is being saved
      onChange({
        target: {
          value: processedValue,
        },
        product_name,
        column_name,
        entry_id,
        index,
      });
    }
    setHasEdited(false); // Reset edit state
  };

  return (
    <div onClick={onClick}>
      <input
        type="text"
        value={is_percent_column ? `${inputValue}%` : inputValue}
        onChange={handleInputChange}
        onKeyDown={handleKeyDown}
        onBlur={handleBlur}
        disabled={disabled}
        className={`column-input ${isSelected ? "selected" : ""}`}
        readOnly={accessLevel === "reader"}
        ref={inputRef || localRef}
      />
    </div>
  );
};

export default ColumnInput;
